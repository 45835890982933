export default [
  {
    id: 0,
    title: 'Business & Quality - Consulting Mexico',
    content:
      'Nos especializamos en el asesoramiento a empresas para la implementación de sistemas ' +
      'de gestión para calidad y mejora continua.',
    customerName: 'Business & Quality - Consulting Mexico',
    customerTitle: 'Producto/Servicio',
    customerDescription: 'Consultoría de negocios',
    customerImage: 'logo-bq.png'
  },
  {
    id: 1,
    title: 'Workshop',
    content:
      'Workshop es una organización concebida con el fin de crear una conexión entre personas ' +
      'con potencial y oportunidades de crecimiento académico y laboral',
    customerName: 'Workshop',
    customerTitle: 'Producto/Servicio',
    customerDescription: 'Talleres',
    customerImage: 'workshop.jpeg'
  },
  {
    id: 2,
    title: 'Unicornio 115',
    content:
      'Especialistas en la venta de bombeo y presurización de agua de uso residencial e ' +
      'industrial , venta de herramienta eléctrica, equipos a gasolina, Herramienta ' +
      'manual y ferretería',
    customerName: 'Unicornio 115',
    customerTitle: 'Producto',
    customerDescription: 'E-commerce',
    customerImage: 'unicornio115.jpeg'
  },
  {
    id: 3,
    title: 'MentAL',
    content:
      'Somos una plataforma que nace de la conciencia, para llevar los recursos y el ' +
      'medio que permite ofrecer servicios que mejoren la calidad de vida en las personas, ' +
      'a través del trabajo mental, emocional, nutricional, médico y físico, contribuyendo ' +
      'al cuidado de la salud con los mejores especialistas en el ramo de la Medicina, ' +
      'Psicología, Nutrición y Entrenamiento deportivo.',
    customerName: 'MentAL',
    customerTitle: 'Producto',
    customerDescription: 'Web app',
    customerImage: 'mental.jpg'
  }
];
