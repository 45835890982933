import React from 'react';
import Card from './Card';
import LangProvider from '../lang/lang-provider';
const _ = LangProvider.getConstants();

const CustomerCard = ({ customer }) => {
  let baseClass =
    'transition duration-500 ease-in-out transform hover:scale-110 border-b-8 border-l-0 border-r-0 border-t-0';
  switch (customer.id) {
    case 0:
      baseClass = baseClass.concat(' border-blue-700');
      break;
    case 1:
      baseClass = baseClass.concat(' border-red-500');
      break;
    case 2:
      baseClass = baseClass.concat(' border-blue-600');
      break;
    case 3:
      baseClass = baseClass.concat(' border-teal-500');
      break;
    default:
      break;
  }
  return (
    <Card className={baseClass}>
      <div className="flex mt-8 h-24 justify-center">
        <img
          className="w-30 h-30 rounded-full object-scale-down"
          src={require(`../assets/${customer.customerImage}`)}
          alt={customer.customerName}
        />
      </div>
      <div className="mt-12 text-center">
        <p className="text-xl font-semibold text-center">{customer.title}</p>
        <p className="mt-2">{_.portfolio.kindOfProduct}</p>
        <p className="text-sm text-gray-600">{customer.customerDescription}</p>
      </div>
    </Card>
  );
};

export default CustomerCard;
