import React from 'react';
import LabelText from '../../components/LabelText';
import customerData from '../../data/customer-data';
import CustomerCard from '../../components/CustomerCard';
import LangProvider from '../../lang/lang-provider';
const _ = LangProvider.getConstants();

const Portfolio = () => (
  <div className="container mx-auto">
    <LabelText className="mb-8 font-bold text-xl text-gray-600 text-center">
      {_.portfolio.title}
    </LabelText>
    <div className="flex flex-col sm:flex-wrap md:flex-row md:-mx-3">
      {customerData.map(customer => (
        <div
          key={customer.customerName}
          className="flex-1 px-3"
          data-sal="flip-left"
          data-sal-duration="1500"
          data-sal-easing="ease"
        >
          <CustomerCard customer={customer} />
        </div>
      ))}
    </div>
  </div>
);

export default Portfolio;
